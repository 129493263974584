import React, { useState, useEffect } from 'react'
import { Box, Text, Button, Badge, Flex } from '@chakra-ui/react'
import { AddIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Season, seasonStateColorMap, useGetGames } from '../data'
import { ManageSeason } from './ManageSeason'
import { Link, useHistory } from 'react-router-dom'
import { useAuthenticatedUser, permissions } from '../Authentication'
import { getSeasonState } from '../helpers/season-state'

export type SeasonPageName =
    | 'prizing'
    | 'expert_tippers'
    | 'add_rounds'
    | 'winners'

export function SeasonForm({
    seasonId,
    page,
}: {
    seasonId: string
    page?: SeasonPageName
}) {
    const { data: games, refetch } = useGetGames()
    const [seasonToManage, setSeasonToManage] = useState<Season>()
    const history = useHistory()

    const user = useAuthenticatedUser()

    useEffect(() => {
        if (seasonId && games) {
            const [sport, seasonName] = seasonId.split('_')
            const season = games.find(
                (x) =>
                    x.season === seasonName &&
                    x.sport.toLocaleLowerCase() === sport.toLocaleLowerCase(),
            )
            setSeasonToManage(season)
        } else {
            setSeasonToManage(undefined)
        }
    }, [games, seasonId])

    if (seasonToManage) {
        return (
            <ManageSeason
                season={seasonToManage}
                page={page}
                refetch={() => refetch()}
            />
        )
    }

    return (
        <Box px="10" maxW={'1900px'} marginX="auto">
            <Box height={100}>
                <Text fontSize={'xx-large'}>Seasons</Text>
                <Button
                    colorScheme="blue"
                    leftIcon={<AddIcon />}
                    onClick={() => history.push('/seasons/newseason')}
                    disabled={user?.hasPermission(permissions.commercial)}
                >
                    Create new season
                </Button>
            </Box>

            <Box height="0.5px" backgroundColor="#65707d77" />

            {['AFL', 'AFLW', 'Cricket', 'NRL', 'WAFL'].map((sport) => (
                <Box mt="10">
                    <Text fontSize={'x-large'} fontWeight="bold">
                        {sport}
                    </Text>
                    <Box display="flex" dir="row">
                        {games
                            ?.filter((g) => g.sport === sport)
                            .map((g) => (
                                <SeasonCard
                                    season={g}
                                    key={`${g.sport}-${g.season}`}
                                />
                            ))}
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

function SeasonCard({ season }: { season: Season }) {
    const state = getSeasonState(season)

    return (
        <Link to={`/seasons/${season.sport}_${season.season}`}>
            <Flex
                border="1px #65707d77 solid"
                borderRadius="10px"
                width="72"
                height="24"
                justify="space-between"
                p="3"
                mr="5"
            >
                <Flex
                    justify="left"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                >
                    <Flex flexDir="row" alignItems="center">
                        <Text fontSize="large" flex="1">
                            {season.sport} {season.season}
                        </Text>
                    </Flex>
                </Flex>

                <Flex
                    flexDir="column"
                    justifyContent="center"
                    gap="2"
                    height="100%"
                >
                    <Flex align="center" justify="center">
                        <Badge
                            height="fit-content"
                            colorScheme={
                                state ? seasonStateColorMap[state] : 'gray'
                            }
                            px="2"
                            py="0.5"
                        >
                            {state ?? 'unknown'}
                        </Badge>
                        <ChevronRightIcon />
                    </Flex>
                    {season.locked && (
                        <Box textAlign="right">
                            <Badge colorScheme="red">Locked</Badge>
                        </Box>
                    )}
                </Flex>
            </Flex>
        </Link>
    )
}
